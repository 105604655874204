<template>
  <main id="content" role="main">
    <div class="container py-3 mn-b3">
      <div class="d-lg-flex justify-content-between align-items-center">
        <div class="mb-3 mb-lg-0">
          <h1 class="h4 mb-0">Token Transaction Details</h1>
        </div>
        <div class="d-flex flex-wrap mt-1 mt-md-0 flex-wrap ml-md-auto"></div>
      </div>
    </div>
    <div class="container space-bottom-2">
      <div class="card">
        <div
          class="
            card-header
            sticky-card-header
            d-flex
            justify-content-between
            p-0
          "
        >
          <ul
            class="nav nav-custom nav-borderless nav_tabs1"
            id="nav_tabs"
            role="tablist"
          >
            <li class="nav-item">
              <a
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                href="#home"
                aria-controls="home"
                aria-selected="true"
                onclick="javascript:updatehash('');"
                >Overview</a
              >
            </li>
          </ul>
        </div>

        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div id="ContentPlaceHolder1_maintable" class="card-body">
              <div class="row align-items-center mt-1">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="A TxHash or transaction hash is a unique 66 characters identifier that is generated whenever a transaction is executed."
                  ></i
                  >Transaction Hash:
                </div>
                <div class="col-md-9">
                  <span id="spanTxHash" class="mr-1">{{ hash }}</span>

                  <a
                    class="js-clipboard text-muted"
                    href="javascript:;"
                    data-toggle="tooltip"
                    title=""
                    data-content-target="#spanTxHash"
                    data-class-change-target="#spanTxHashLinkIcon"
                    data-success-text="Copied"
                    data-default-class="far fa-copy"
                    data-success-class="far fa-check-circle"
                  >
                    <span id="spanTxHashLinkIcon" class="far fa-copy"></span>
                  </a>
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The number of the block in which the transaction was recorded. Block confirmation indicate how many blocks since the transaction is validated."
                  ></i
                  >Block:
                </div>
                <div class="col-md-9">
                  <a
                    href="#"
                    v-on:click.prevent="showBlock(transactionData.blockNumber)"
                    >{{ transactionData.blockNumber }}</a
                  >
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The date and time at which a transaction is validated."
                  ></i
                  >Timestamp:
                </div>
                <div class="col-md-9">
                  <span id="clock"></span
                  ><i class="far fa-clock small mr-1"></i> {{ showTime
                  }}{{ getName("Common", "ago") }} ({{
                    formatDate(transactionData.timestamp, "yyyy-MM-dd hh:mm:ss")
                  }}
                  )
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row align-items-center">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The sending party of the transaction (could be from a contract address)."
                  ></i
                  >From:
                </div>
                <div class="col-md-9">
                  <span id="spanFromAdd" style="display: none">{{
                    transactionData.from
                  }}</span
                  ><a
                    id="addressCopy"
                    class="mr-1"
                    href="#"
                    v-on:click.prevent="
                      showAddressTransaction(transactionData.from)
                    "
                    >{{ transactionData.from }}</a
                  >

                  <a
                    class="js-clipboard text-muted ml-1"
                    href="javascript:;"
                    data-toggle="tooltip"
                    title=""
                    data-content-target="#spanFromAdd"
                    data-class-change-target="#fromAddressLinkIcon"
                    data-success-text="Copied"
                    data-default-class="far fa-copy"
                    data-success-class="far fa-check-circle"
                  >
                    <span id="fromAddressLinkIcon" class="far fa-copy"></span>
                  </a>
                </div>
              </div>

              <hr class="hr-space" />

              <div class="row">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The receiving party of the transaction (could be a contract address)."
                  ></i
                  >To:
                </div>
                <div class="col-md-9">
                  <a
                    id="contractCopy"
                    href="#"
                    v-on:click.prevent="
                      showAddressTransaction(transactionData.to)
                    "
                    class="wordwrap mr-1"
                    >{{ transactionData.to }}</a
                  >
                  <span id="spanToAdd" style="display: none">{{
                    transactionData.to
                  }}</span>
                  <a
                    class="js-clipboard text-muted ml-1"
                    href="javascript: ;"
                    data-toggle="tooltip"
                    title=""
                    data-content-target="#spanToAdd"
                    data-class-change-target="#spanToAddResult"
                    data-success-text="Copied"
                    data-default-class="far fa-copy"
                    data-success-class="far fa-check-circle"
                  >
                    <span id="spanToAddResult" class="far fa-copy"></span>
                  </a>
                </div>
              </div>
              <hr class="hr-space" />
              <div class="row align-items-center mn-3">
                <div
                  class="
                    col-md-3
                    font-weight-bold font-weight-sm-normal
                    mb-1 mb-md-0
                  "
                >
                  <i
                    class="
                      fal
                      fa-question-circle
                      text-secondary
                      d-none d-sm-inline-block
                      mr-1
                    "
                    data-container="body"
                    data-toggle="popover"
                    data-placement="top"
                    data-original-title=""
                    title=""
                    data-content="The value being transacted in HT and fiat value. Note: You can click the fiat value (if available) to see historical value at the time of transaction."
                  ></i
                  >Value:
                </div>
                <div class="col-md-9">
                  <span id="ContentPlaceHolder1_spanValue"
                    ><span
                      data-toggle="tooltip"
                      title="The amount of HT to be transferred to the recipient with the transaction"
                      ><span
                        class="
                          u-label u-label--value u-label--secondary
                          text-dark
                          rounded
                          mr-1
                        "
                        >{{ transactionData.value }} {{ this.symbol }}</span
                      >
                    </span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <div id="push"></div>
</template>

<script>
import { getName } from "../../public/assets/js/lang.js";
import { PostNet, PostNetUrl } from "../../public/assets/js/myRequest.js";
import { importJS, getUrlKey } from "../../src/assets/js/utils.js";
import route from "../router/index";
import { formatNumber } from "../../public/assets/js/utils.js";

export default {
  name: "TxTokenDetail",
  data() {
    return {
      hash: "",
      transactionData: "",
      symbol: "",
      showTime: "",
      loading: false,
      interval: null,
    };
  },
  mounted() {
    this.hash = getUrlKey("h");
    this.loading = true;
    PostNet.PostWith(
      PostNetUrl.getTokenTransactionDetail,
      (info) => {
        this.loading = false;
        this.transactionData = info.data;
        if (info.data) {
          this.symbol = info.data.symbol;
          this.$nextTick(function () {
            importJS("/assets/js/header/js-clipboard.js");
          });
        }
      },
      (err) => {
        this.loading = false;
      },
      { hash: this.hash }
    );
    this.interval = setInterval(() => {
      this.caluetTime();
    }, 1000);
  },
  beforeUnmount() {
    if (this.interval != null) {
      clearInterval(this.interval);
    }
  },
  methods: {
    // 一些必须的方法吧
    getName(str1, str2) {
      return getName(str1, str2);
    },
    formatDate(time, fmt) {
      if (time == 0) {
        return "";
      }
      time = time * 1000;
      let date = new Date(time);
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      }
      let o = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          let str = o[k] + "";
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length === 1 ? str : this.padLeftZero(str)
          );
        }
      }
      return fmt;
    },
    padLeftZero(str) {
      return ("00" + str).substr(str.length);
    },
    formatNumber(number, decimail) {
      return formatNumber(number, decimail);
    },
    // 本页面方法
    caluetTime() {
      if (!this.transactionData) {
        return;
      }
      try {
        if (!isNaN(this.transactionData.timestamp)) {
          const myDate = new Date();
          const now = myDate.getTime();
          const dis = now - this.transactionData.timestamp * 1000;
          var t = 0;
          if (dis < 0) {
            this.showTime = 0 + " " + getName("Home", "sec");
          } else if (dis < 60000) {
            t = parseInt(dis / 1000);
            this.showTime = t + " " + getName("Home", t > 1 ? "secs" : "sec");
          } else if (dis < 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60);
            this.showTime = t + " " + getName("Home", t > 1 ? "mins" : "min");
          } else if (dis < 24 * 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60 / 60);
            this.showTime = t + " " + getName("Home", t > 1 ? "hours" : "hour");
          } else if (dis < 365 * 24 * 60 * 1000 * 60) {
            t = parseInt(dis / 1000 / 60 / 60 / 24);
            this.showTime = t + " " + getName("Home", t > 1 ? "days" : "day");
          } else {
            t = parseInt(dis / 1000 / 60 / 60 / 24 / 365);
            this.showTime = t + " " + getName("Home", t > 1 ? "years" : "year");
          }
          this.showTime = this.showTime + " " + getName("Home", "ago");
        }
      } catch (e) {
        console.log(e);
      }
    },
    showBlock: function (blockNum) {
      // location.href = "/block?b=" + blockNum;
      route.push({
        path: "/block",
        query: {
          b: blockNum,
        },
      });
    },
    showAddressTransaction: function (addr) {
      // location.href = "/address?a=" + addr;
      route.push({
        path: "/address",
        query: {
          a: addr,
        },
      });
    },
  },
};
</script>
